import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ZipInput(props) {
  const location = useLocation();
  let queryStringParams = queryString.parse(location.search);
  
  const {
    placeholder,
    name,
    maxlength,
    value,
    onChange,
    isIncorrect,
    id,
    type,
    inputMode,
    className,
    isNewDesign = false
  } = props;

  return (
    <div className={classnames(styles.Wrapper, { [styles.NewDesign]: isNewDesign })}>
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        className={classnames(
          className,
          styles.Input,
          { [styles.Input__isIncorrect]: isIncorrect },
        )}
        maxLength={maxlength}
        autoComplete="off"
        value={value}
        onChange={e => onChange(e)}
        inputMode={inputMode}
      />
    </div>
  );
}
