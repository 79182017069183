import React, {useState, lazy, Suspense} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import queryString from 'query-string'
//import TagManager from 'react-gtm-module';

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Landing from 'views/Landing/Landing'
import OneClick from 'views/OneClick/OneClick'
import ScrollToTop from 'hooks/useScrollToTop'
import {Helmet} from 'react-helmet'
import {useCookies} from 'react-cookie'
import {useOnce} from 'hooks/useOnce'

const ResultRates = lazy(() => import('views/ResultRates/ResultRates'))
const Contact = lazy(() => import('views/Contact/Contact'))
const CCPA = lazy(() => import('views/CCPA/CCPA'))
const About = lazy(() => import('views/About/About'))
const Terms = lazy(() => import('views/Terms/Terms'))
const PrivacyPolicy = lazy(() => import('views/PrivacyPolicy/PrivacyPolicy'))
const MarketingPartners = lazy(() =>
  import('views/MarketingPartners/MarketingPartners'),
)
const AdvertisingDisclosure = lazy(() =>
  import('views/AdvertisingDisclosure/AdvertisingDisclosure'),
)
const Error404 = lazy(() => import('./views/404/404'))
const Form = lazy(() => import('views/Form/FormWrapper'))

const routes = [
  {
    component: Landing,
    path: '/',
  },
  {
    component: Landing,
    path: '/:a(aws-hc-app.*)',
  },
  {
    component: Landing,
    path: '/auto-insurance',
    props: {
      isShort: true,
    },
    logoLinkDisabled: true,
    hideFooterForm: true,
  },
  {
    component: Form,
    path: '/form',
    logoLinkDisabled: true,
    hideFooterForm: true,
  },
  {
    component: ResultRates,
    path: '/results',
    logoLinkDisabled: true,
    hideFooterForm: true,
  },
  {
    component: Contact,
    path: '/contact',
  },
  {
    component: CCPA,
    path: '/privacy-rights',
  },
  {
    component: About,
    path: '/about',
  },
  {
    component: Terms,
    path: '/terms',
  },
  {
    component: PrivacyPolicy,
    path: '/privacy-policy',
  },
  {
    component: MarketingPartners,
    path: '/marketing-partners',
  },
  {
    component: AdvertisingDisclosure,
    path: '/advertising-disclosure',
  },
  {
    component: MarketingPartners,
    path: '/farmers-group',
    props: {
      pageName: 'farmers-group',
    },
  },
  {
    component: MarketingPartners,
    path: '/farmers-group-exchanges',
    props: {
      pageName: 'farmers-group-exchanges',
    },
  },
]

/*
const tagManagerArgs = {
  gtmId: 'GTM-TV8ZV39'
};
TagManager.initialize(tagManagerArgs);
*/

export const AppRouter = () => {
  const [logoLinkDisabled, setLogoLinkDisabled] = useState(false)
  const [hideFooterForm, setHideFooterForm] = useState(false)

  const [isLoadingOneClick, setIsLoadingOneClick] = useState(() => window.passedBasicOneClickValidation ?? false);
  const [oneClickData, setOneClickData] = useState(null)

  let queryStringParams = queryString.parse(window.location.search)
  const [cookies] = useCookies();
  const isFcc2 = queryStringParams['cx_nc'] === '2' || cookies.cx_nc === '2'
  const isFcc5 = queryStringParams['cx_nc'] === '5' || cookies.cx_nc === '5'
  const isFcc = isFcc5 || isFcc2;

  const routeRenderHandler = (props, route) => {
    setLogoLinkDisabled(route.logoLinkDisabled || false)
    setHideFooterForm(route.hideFooterForm || false)
    if (
      ['/', '/form', '/auto-insurance', '/results'].indexOf(route.path) >= 0
    ) {
      let queryParams = queryString.parse(window.location.search)
      if (
        !queryParams['utm_source'] ||
        queryParams['utm_source'].toLowerCase() !== 'surema'
      )
        window.fbq && window.fbq('track', 'PageView')
    }
    if (route.path === '/' || route.path === '/auto-insurance') {
      window.gtag &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-640072390/BluVCMjlv88BEMb1mrEC',
        })
    }
    if (route.path === '/results') {
      window.gtag &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-640072390/03QCCN3Lzs8BEMb1mrEC',
        })
    }
    return React.createElement(route.component, {
      ...props,
      ...route.props,
      isLoadingOneClick,
      oneClickData,
    })
  }

  let organizationSchemaJson = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Organization',
    name: 'Sureint',
    url: window.location.origin,
    logo: window.location.origin + '/assets/images/site-logo-large.jpg',
    sameAs: [
      'https://www.facebook.com/SureintInsurance',
      'https://www.instagram.com/sureintinsurance/',
      'https://twitter.com/Sureintofficial',
      'https://www.youtube.com/channel/UCNaCo483fDbwMA2FC9ISzCA',
    ],
  })

  useOnce(() => {
    window.getFormConfig
      .then(x => {
        if (x.oneClickData && window.passedBasicOneClickValidation && !isFcc) {
          setOneClickData({...x.oneClickData, form: x.defaultData})
        }
        setIsLoadingOneClick(false)
      })
      .catch(() => setIsLoadingOneClick(false))
  })

  return (
    <Router>
      <Suspense fallback="">
        <Helmet>
          <title>Auto Insurance. The Intelligent Way. | Sureint</title>
          <meta
            name="description"
            content="Use our fast & free service to find better rates intelligently. Because when you learn what impacts the price of your quote, you can get the best rates."
          />
          <script type="application/ld+json">{organizationSchemaJson}</script>
        </Helmet>
        <ScrollToTop />
        <Header disableLogoLink={logoLinkDisabled} isOneClickRoute={oneClickData ? true : false} />
        <Switch>
          {routes.map(route => (
            <Route
              exact
              key={route.path}
              path={route.path}
              render={props => routeRenderHandler(props, route)}
            />
          ))}
          <Route component={Error404} />
        </Switch>
        <Footer hideForm={hideFooterForm} isOneClickRoute={oneClickData ? true : false} />
      </Suspense>
    </Router>
  )
}
