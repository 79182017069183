import React from 'react';

import styles from './styles.module.scss';

export const BulletList = ({
  list,
}) => {

  return (
    <ul className={styles.BulletList}>
      {list.map((item, index) => (
        <li className={styles.BulletListItem} key={index}>{item}</li>
      ))}
    </ul>
  );
}
