import React from 'react';
import styles from './checkbox.module.scss'
import classNames from 'classnames';



export default function Checkbox({ label, className, tfWrapperRole, ...otherProps}) {
  return (
    <label className={classNames(styles.checkboxWrapper, className)} data-tf-element-role={tfWrapperRole}>
      <span className={styles.label}>{label}</span>
      <input {...otherProps} type="checkbox"/>
      <span className={styles.checkmark}>
        <span className={styles.after}></span>
      </span>
    </label>
  )
}
