import React from 'react';
import { ModalProvider } from 'react-modal-hook/dist';

import { AppRouter } from './AppRouter';

function App() {
  return (
    <ModalProvider>
      <AppRouter/>
    </ModalProvider>
  );
}

export default App;
