import queryString from 'query-string';
import { Cookies } from 'react-cookie';

export class LeadService  {

  static createEmptyLead(data, isFcc = false) {
    let path = isFcc ? '/marketplace/CreateEmptyLead' : '/lead/CreateEmptyLead';
    return fetch(path, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({status: 'Vehicle Make', ...data})
    });
  }

  static appendLeadId(str) {
    let leadId = this.getLeadId();
    return str + (leadId ? '?leadId=' + leadId : '');
  }

  static getLeadId() {
    let emptyGuid = '00000000-0000-0000-0000-000000000000';
    let urlParams = queryString.parse(window.location.search);
    let leadId = urlParams['leadId'];
    if ((!leadId || leadId == emptyGuid) && Cookies) {
      let cookies = new Cookies();
      leadId = cookies.get('leadId');
    }
    if (leadId && leadId != emptyGuid) {
      return leadId;
    }
    return null;
  }

  static updateLead(data,isFcc = false) {
    let path = isFcc ? '/marketplace/UpdateLead' : '/lead/UpdateLead';
    return fetch(this.appendLeadId(path), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...data, status: 'Vehicle Make' })
    });
  }

  static updateStatus(status,isFcc = false) {
    let formData = new FormData();
    let path = isFcc ? '/marketplace/UpdateStatus' : '/lead/UpdateStatus';
    formData.append('status', status);
    fetch(this.appendLeadId(path), {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'status=' + encodeURIComponent(status)
    });
  }

  static saveData(data) {
    let path = '/lead/SaveData';
    return fetch(this.appendLeadId(path), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  }

    static GetResults(isFcc = false) {
        var startAdsTradingUTCTime = new Date().toUTCString();
        let path = isFcc ? '/marketplace/GetResults' : '/lead/GetResults';
        return fetch(this.appendLeadId(path) + '&clientRequestTimeUtc=' + startAdsTradingUTCTime).then(res => res.json());
    }
    
    static ReportAdsDurationTimeToServer(startedTradeEpochDate, finishedTradeEpochDate, adsCount, extraData){
      let url = this.appendLeadId('/data/ReportAdsDurationTimeToServer') + 'tradingStartDateEpochInMilliseconds=' + encodeURI(startedTradeEpochDate) + 'tradingFinishDateEpochInMilliseconds=' + encodeURI(finishedTradeEpochDate) + '&adsCount=' + encodeURI(adsCount);
        if (extraData) {
            url += '&extraData=' + encodeURIComponent(extraData);
        }
        return fetch(url, {method: 'post'});
    }

    static ping(data) {
        return fetch(this.appendLeadId('/marketplace/StartAuction'), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...data })
      });
    }

    static post(data) {
      return fetch(this.appendLeadId('/marketplace/FinishAuction'), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...data })
      });
    }
}