import React from 'react';
import Slider from "react-slick";

import styles from './styles.module.scss';
import classnames from 'classnames';

export const Carousel = ({
  slides,
  imgClassName
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <div className={styles.SliderWrapper}>
      <Slider {...settings}>
        {slides.map((item, index) => (
          <div className={styles.SlideWrapper} key={index}>
            <img
              fetchpriority={index > 0 ? 'low' : ''}
              className={classnames(styles.SlidePicture, imgClassName)}
              src={item.image}
              alt={item.title}
            />
            <div className={styles.TextWrapper}>
              <p className={styles.SlideTitle}>{item.title}</p>
              <p className={styles.SlideSubtitle}>{item.subtitle}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
