import React from 'react';
import styles from './styles.module.scss';

export default function Companies() {
  return (
    <>
    <div className={styles.companiesDivier}></div>
    <div className={styles.mainCompanies}>
      <div className={styles.mainCompaniesText}>
        Compare best quotes from top insurance carriers, including:
      </div>
      <div className={styles.companiesLogos}>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet="/Assets/images/companies/liberty-td.png"
            width="132"
            height="52"
          />
          <source
            srcSet="/Assets/images/companies/liberty.png 1x, /Assets/images/companies/liberty@2x.png 2x, /Assets/images/companies/liberty@3x.png 3x"
            width="121"
            height="48"
          />
          <img
            src="/Assets/images/companies/liberty.png"
            alt="liberty"
            width="121"
            height="48"
          />
        </picture>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet="/Assets/images/companies/progressive-td.png"
            width="134"
            height="16"
          />
          <source
            srcSet="/Assets/images/companies/progressive.png 1x, /Assets/images/companies/progressive@2x.png 2x, /Assets/images/companies/progressive@3x.png 3x"
            width="107"
            height="14"
          />
          <img
            src="/Assets/images/companies/progressive.png"
            width="107"
            height="14"
            alt="progressive"
          />
        </picture>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet="/Assets/images/companies/thehartford-td.png"
            width="98"
            height="52"
          />
          <source
            srcSet="/Assets/images/companies/thehartford.png 1x, /Assets/images/companies/thehartford@2x.png 2x, /Assets/images/companies/thehartford@3x.png 3x"
            width="79"
            height="42"
          />
          <img
            src="/Assets/images/companies/thehartford.png"
            alt="thehartford"
            width="79"
            height="42"
          />
        </picture>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet="/Assets/images/companies/direct-td.png"
            width="98"
            height="52"
          />
          <source
            srcSet="/Assets/images/companies/direct.png 1x, /Assets/images/companies/direct@2x.png 2x, /Assets/images/companies/direct@3x.png 3x"
            width="80"
            height="42"
          />
          <img
            src="/Assets/images/companies/direct.png"
            alt="direct"
            width="80"
            height="42"
          />
        </picture>
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet="/Assets/images/companies/kemper-td.png"
            width="132"
            height="52"
          />
          <source
            srcSet="/Assets/images/companies/kemper.png 1x, /Assets/images/companies/kemper@2x.png 2x, /Assets/images/companies/kemper@3x.png 3x"
            width="106"
            height="42"
          />
          <img
            src="/Assets/images/companies/kemper.png"
            alt="kemper"
            width="106"
            height="42"
          />
        </picture>
      </div>
    </div>
    </>
  )
}
