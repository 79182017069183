import Step1Desktop from 'assets/d-step1.png';
import Step2Desktop from 'assets/d-step2.png';
import Step3Desktop from 'assets/d-step3.png';

import Step1Mobile from 'assets/m-step1.png';
import Step2Mobile from 'assets/m-step2.png';
import Step3Mobile from 'assets/m-step3.png';

import NewStep1Desktop from 'assets/nd-step1.png';
import NewStep2Desktop from 'assets/nd-step2.png';
import NewStep3Desktop from 'assets/nd-step3.png';

import NewStep1Mobile from 'assets/nm-step1.png';
import NewStep2Mobile from 'assets/nm-step2.png';
import NewStep3Mobile from 'assets/nm-step3.png';

import InfoBlockImage1 from 'assets/info-block-1.svg';
import InfoBlockImage2 from 'assets/info-block-2.svg';
import InfoBlockImage3 from 'assets/info-block-3.svg';
import InfoBlockImage4 from 'assets/info-block-4.svg';
import InfoBlockImage5 from 'assets/info-block-5.svg';

export const HEADER_CHANGE_SCROLL_HEIGHT = 800;
export const PROGRESS_STEPS = 4;
export const MAX_FORM_VEHICLES_AMOUNT = 4;
export const MAX_ZIP_INPUT_LENGTH = 5;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBERS_REGEX = /^\d+$/;
export const ADDRESS_REGEX = /\w+\s+\w+/;
export const PHONE_REGEX = /\(?\d{3,3}\)?\s*\d{3,3}\s*\-?\s*\d{4,4}$/;

const isMobile = window.innerWidth < 500;


export const SLIDES = [
  {
    image: isMobile ? Step1Mobile : Step1Desktop,
    title: 'Step 1',
    subtitle: 'Fill in our intelligent form and see how it affects your policy.',
  },
  {
    image: isMobile ? Step2Mobile : Step2Desktop,
    title: 'Step 2',
    subtitle: 'Our insurance engine will calculate the best possible policies for you.',
  },
  {
    image: isMobile ? Step3Mobile : Step3Desktop,
    title: 'Step 3',
    subtitle: 'Choose between the most relevant companies and save intelligently.',
  }
];

export const ND_SLIDES = [
  {
    image: isMobile ? NewStep1Mobile : NewStep1Desktop,
    title: 'Step 1',
    subtitle: 'Fill in our intelligent form and see how it affects your policy.',
  },
  {
    image: isMobile ? NewStep2Mobile : NewStep2Desktop,
    title: 'Step 2',
    subtitle: 'Our insurance engine will calculate the best affordable policies for you.',
  },
  {
    image: isMobile ? NewStep3Mobile : NewStep3Desktop,
    title: 'Step 3',
    subtitle: 'Choose between insurance companies and save on your car insurance.',
  }
];

export const LIST_ONE = [
  'How personal information impacts your policy.',
  'How vehicle details affect your monthly costs.',
  'How additional features can be used to save.',
];

export const LIST_TWO = [
  'Primary use of your vehicle',
  'Driving distances (annual mileage)',
  'Your desired coverage level',
  'The level of your education',
];

export const INFO_BLOCKS_DATA = [
  {
    image: InfoBlockImage1,
    text: 'Bundling additional insurance policies with the same provider (auto & home).',
  },
  {
    image: InfoBlockImage2,
    text: 'Shopping around and comparing policies will result in a better priced policy.',
  },
  {
    image: InfoBlockImage3,
    text: 'Multiple cars on the same policy usually costs less than seperate policies.',
  },
  {
    image: InfoBlockImage4,
    text: 'Keep a clean driving record or take a defensive driving course to reduce costs.',
  },
  {
    image: InfoBlockImage5,
    text: 'Maintain high grades as a good student to be considered a safer driver.',
  }
];

export const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export const MONTHS = [ "January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December" ];

export const YEARS = [1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000];

export const FORM_SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  swipe: false,
  arrows: false,
  autoplay: false,
  adaptiveHeight: true,
};
