import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { HEADER_CHANGE_SCROLL_HEIGHT } from 'consts';

import { Button } from 'components/Button/Button';

import styles from './styles.module.scss';

export default function Header(props) {
  const [shouldChangeHeader, setShouldChangeHeader] = useState(false);
  const [shouldAddButtonHeader, setShouldAddButtonHeader] = useState(false);
  const prevShouldAddButtonHeaderRef = useRef();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  let location = useLocation();
  let history = useHistory();
  const { disableLogoLink, isOneClickRoute } = props;
  const isLandingHeader =
    location.pathname === '/'
    || location.pathname === '/contact'
    || location.pathname === '/ccpa'
    || location.pathname === '/about'
    || location.pathname === '/terms'
    || location.pathname === '/privacy-policy'
    || location.pathname === '/marketing-partners';
  const isNewDesign = location.pathname === '/auto-insurance' && (location.search.indexOf('v=2') > 0 || location.search.indexOf('v=3') > 0);

  const listenScrollEvent = useCallback(
    () => {
      if (window.scrollY > HEADER_CHANGE_SCROLL_HEIGHT) {
        setShouldAddButtonHeader(true);
      }
      if (window.scrollY < HEADER_CHANGE_SCROLL_HEIGHT) {
        setShouldAddButtonHeader(false);
      }
      if (window.scrollY > 5) {
        setShouldChangeHeader(true);
      }
      if (window.scrollY < 5) {
        setShouldChangeHeader(false);
      }
    },
    [],
  );

  useEffect(
    () => {
      if (isLandingHeader) {
        window.addEventListener('scroll', listenScrollEvent);
        prevShouldAddButtonHeaderRef.current = shouldAddButtonHeader;
      }
      return () => {
        window.removeEventListener('scroll', listenScrollEvent)
      };
    },
    [listenScrollEvent, shouldAddButtonHeader, isLandingHeader],
  );

  const prevShouldAddButtonHeader = prevShouldAddButtonHeaderRef.current;

  return (
    <header
      className={classnames(
        styles.Header,
        { [styles.Header__isChanged]: shouldChangeHeader },
        { [styles.Header__isOutsideLanding]: !isLandingHeader },
        { [styles.Header__newDesign]: isNewDesign }
      )}
    >
      {disableLogoLink || isOneClickRoute ? <span className={styles.Logo}></span> : <Link to={'/'} className={styles.Logo} />}
      <Fragment>
        {isTabletOrMobile
          ? (
            <Fragment>
              {isLandingHeader && (
                <Fragment>
                  <Button
                    className={classnames(
                      styles.CallButton,
                      { [styles.CallButton__shown]: shouldAddButtonHeader },
                      { [styles.CallButton__hidden]: !shouldAddButtonHeader && prevShouldAddButtonHeader },
                    )}
                    label="Get Quote"
                    onClick={() => history.push('/')}
                  />
                </Fragment>
              )}
            </Fragment>
          )
          : (isLandingHeader && !isOneClickRoute ?
            <div className={styles.CallWrapper}>
              <Button
                  className={classnames(
                    styles.CallButton,
                    { [styles.CallButton__shown]: shouldAddButtonHeader },
                    { [styles.CallButton__hidden]: !shouldAddButtonHeader && prevShouldAddButtonHeader },
                  )}
                  label="Get Quote"
                  onClick={() => history.push('/')}
                />
            </div> : null)
        }
      </Fragment>
    </header>
  );
}
