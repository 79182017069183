import React, { useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { Button } from 'components/Button/Button';
import ZipInput from 'components/Input/ZipInput/ZipInput';

import FacebookIcon from 'assets/facebook.svg';
import InstagramIcon from 'assets/instagram.svg';
import TwitterIcon from 'assets/twitter.svg';
import YoutubeIcon from 'assets/youtube.svg';

import styles from './styles.module.scss';

export default function Footer({ hideForm, isOneClickRoute }) {
  let history = useHistory();
  const [zipInputValue, setZipInputValue] = useState('');
  const [isIncorrectInput, setIsIncorrectInput] = useState(false);

  const handleSubmitZipCode = useCallback(
    () => {
      if (!zipInputValue || zipInputValue.length < 5) {
        setIsIncorrectInput(true);
      } else {
        setIsIncorrectInput(false);
        history.push("/?zip=" + zipInputValue);
      }
    },
    [zipInputValue, history],
  );

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <footer className={styles.Footer}>
      <div className={styles.FlexWrapper}>
        <div className={styles.LinksAndZip}>
          <div className={styles.LinksWrapper}>
            <div className={styles.Links}>
              <h3 className={styles.LinksTitle}>Company</h3>
              <Link className={styles.Link} to="/contact">Contact Us</Link> 
              <Link className={styles.Link} to="/about">About Us</Link>
              {
                !isOneClickRoute ?
                  <Link className={styles.Link} to="/">Home</Link> :
                  <a className={styles.Link} href="/">Home</a>
              }
            </div>
            <div className={styles.Links}>
              <h3 className={styles.LinksTitle}>Legal</h3>
              <a className={styles.Link} href="/privacy-policy#spr">State Privacy Rights</a>
              <a className={styles.Link} href="/privacy-rights">Do Not Sell or Share My Personal Information</a>
              <a className={styles.Link} href="/terms">Terms of Use</a>
              <a className={styles.Link} href="/privacy-policy">Privacy Policy</a>
              <a className={styles.Link} style={{order: 4}} href="/advertising-disclosure">Advertising Disclosure
              </a>
              <a className={styles.Link} style={{order: 5}} href="/privacy-policy#adChoices">AdChoices</a>
            </div>
            {isMobile
              ? (
                <div className={styles.LinksIcons}>
                  <h3 className={styles.LinksTitle}>Social Media</h3>
                  <a className={styles.Link} href="https://www.facebook.com/SureintInsurance" target="_blank">
                    <img src={FacebookIcon} alt="facebook" />
                  </a>
                  <a className={styles.Link} href="https://www.instagram.com/sureintinsurance/" target="_blank">
                    <img src={InstagramIcon} alt="instagram" />
                  </a>
                  <a className={styles.Link} href="https://twitter.com/Sureintofficial" target="_blank">
                    <img src={TwitterIcon} alt="twitter" />
                  </a>
                  <a className={styles.Link} href="https://www.youtube.com/channel/UCNaCo483fDbwMA2FC9ISzCA" target="_blank">
                    <img src={YoutubeIcon} alt="youtube" />
                  </a>
                </div>
              )
              : (
                <div className={styles.Links}>
                  <h3 className={styles.LinksTitle}>Social Media</h3>
                  <a className={styles.Link} href="https://www.facebook.com/SureintInsurance" target="_blank">Facebook</a>
                  <a className={styles.Link} href="https://www.instagram.com/sureintinsurance/" target="_blank">Instagram</a>
                  <a className={styles.Link} href="https://twitter.com/Sureintofficial" target="_blank">Twitter</a>
                  <a className={styles.Link} href="https://www.youtube.com/channel/UCNaCo483fDbwMA2FC9ISzCA" target="_blank">Youtube</a>
                </div>
              )
            }
          </div>
          {!hideForm && <div className={styles.ZipWrapper}>
            <label htmlFor="zip-code-footer" className={styles.LinksTitle}>Find Insurance Intelligently</label>
            <ZipInput
              id="zip-code-footer"
              name="zip"
              placeholder="Zip Code"
              pattern="[0-9]*"
              maxlength="6"
              type="text"
              value={zipInputValue}
              isIncorrect={isIncorrectInput}
              onChange={e => setZipInputValue(e.target.value)}
            />
            <Button
              className={styles.Button}
              label="Get Started"
              onClick={handleSubmitZipCode}
              withArrow
            />
          </div>}
        </div>
        <p className={styles.FooterText}>
          Disclaimer: This website and its operators are not an insurance provider, not an insurance company, not an insurance broker and do not make insurance decisions. We do not represent or are agents to any broker or insurance company and do not endorse any particular broker or insurance provider. We will submit the information you provide to a broker and/or an insurance provider. This website does not constitute an offer or solicitation for automobile or other insurance. Providing your information on this Site does not guarantee that you will be approved for automobile or other insurance. Not all insurance providers can or will insure your vehicle. The quotes, rates or savings advertised by\on this website are not necessarily available from all providers or advertisers. Your actual quotes, rates or savings will vary based on many different factors like: Coverage Limits, Deductibles, Driving History, Education, Occupation, Type of vehicle, Location & more. For questions regarding your insurance policy, please contact your broker or insurance company directly. Residents of several states may not be eligible for insurance or may be subject to large premiums. 
          <br />
          You are under no obligation to use our website or service to initiate contact, nor apply for insurance or any product with any broker or insurance company. We receive compensation, in the form of referral fees, from the insurance carriers, brokers, agents or other offers that we direct you to. Therefore, the amount of compensation provided, along with other factors, may impact which policy / offer you are presented. The offer you receive may be coming from the company that bid the most for your information. This website does not always provide you with an offer with the best rates or terms. Our website does not include all companies or all available offers. Using the service may have results that vary according to individual information provided. We encourage you to research all available insurance policy options relative to your situation. All trademarks and copyrights are the property of their respective owners.
          <br />
                  * The results may vary due to the information you provide us. Based on the NAIC 2018 / 2019 Auto Insurance Database Report, the Avg.monthly expenditure for auto insurance is $89.
        </p>
        <p className={styles.Copyright}>{`© ${new Date().getFullYear()} Sureint.com All rights reserved`}.</p>
      </div>
    </footer>
  );
}
