import React, { Fragment } from 'react';

import styles from './styles.module.scss';

export const InfoBlocks = ({
  info,
}) => {
  return (
    <Fragment>
      <div className={styles.TextWrapper}>
        <h3 className={styles.ColorText}>Our Insurance Engine Can Help You Save!</h3>
        <h2 className={styles.SectionTitle}>
          The Knowledge of Reducing The Price
        </h2>
        <p className={styles.SectionText}>
          When you learn exactly what impacts the price of  your policy, you gain the ability to utilize special  discounts. Here are a few of the most popular  ways Sureint recommends to save on your car  insurance.
        </p>
      </div>
      <Fragment>
        {info.map((item, index) => (
          <div className={styles.InfoBlock} key={index}>
            <img
              className={styles.InfoBlockImage}
              src={item.image}
              alt="info block"
            />
            <p className={styles.InfoBlockText}>{item.text}</p>
          </div>
        ))}
      </Fragment>
    </Fragment>
  );
}
